import Slider from "react-slick";
import * as S from "./styles";
import EpocaNegociosSrcSm from "../../../assets/img/epoca_negocios_logo_sm.png";
import ValorSrcSm from "../../../assets/img/valor_logo_sm.png";
import TelesinteseSrcSm from "../../../assets/img/telesintese_logo_sm.png";
import MinhaoperadoraSrcSm from "../../../assets/img/minhaoperadora_logo_sm.png";
import MeioemensagemSrcSm from "../../../assets/img/meioemensagem_logo_sm.png";
import InfomoneySrcSm from "../../../assets/img/infomoney_logo_sm.png";
import EstadaoSrcSm from "../../../assets/img/estadao_logo_sm.png";
import ItForumSrcSm from "../../../assets/img/itforum_logo_sm.png";
import CanalSolarSrcSm from "../../../assets/img/canalsolar_logo_sm.png";

const MediaComponent = () => {
  const settings = {
    infinite: true, // Loop infinito
    speed: 500, // Velocidade da transição
    slidesToShow: 6, // Quantidade de slides mostrados ao mesmo tempo
    slidesToScroll: 1, // Quantidade de slides para avançar por vez
    autoplay: true,
    autoplaySpeed: 3000, // Velocidade do auto-play (1 segundos)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <S.Media>
      <S.MediaRow>
        <h2>GUD na mídia</h2>
      </S.MediaRow>

      <S.CarouselWrapper>
        <Slider {...settings}>
          <S.MediaImg
            target="__blank"
            href="https://telesintese.com.br/gud-energia-joint-venture-de-vivo-e-auren-comeca-a-operar/"
          >
            <img src={TelesinteseSrcSm} alt="Telesintese" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://epocanegocios.globo.com/empresas/noticia/2024/01/superintendencia-do-cade-aprova-joint-venture-entre-auren-comercializadora-e-telefonica.ghtml"
          >
            <img src={EpocaNegociosSrcSm} alt="Epoca Negócios" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://valor.globo.com/empresas/noticia/2023/12/18/telefnica-brasil-e-auren-energia-formam-joint-venture-focada-em-energia-renovvel.ghtml"
          >
            <img src={ValorSrcSm} alt="Valor Econômico" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://www.minhaoperadora.com.br/2024/06/vivo-e-auren-anunciam-inicio-da-operacao-da-gud-energia.html"
          >
            <img src={MinhaoperadoraSrcSm} alt="Minha operadora" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://www.meioemensagem.com.br/marketing/vivo-e-auren-criam-joint-venture-no-setor-de-energia"
          >
            <img src={MeioemensagemSrcSm} alt="Meio e Mensagem" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://www.infomoney.com.br/business/gud-energia-nasce-com-dna-de-gente-grande-para-abocanhar-mercado-livre-de-energia/"
          >
            <img src={InfomoneySrcSm} alt="Infomoney" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://www.estadao.com.br/economia/coluna-do-broad/vivo-e-a-auren-iniciam-operacao-para-desbravar-mercado-livre-de-energia/"
          >
            <img src={EstadaoSrcSm} alt="Estadão" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://canalsolar.com.br/vivo-auren-joint-venture-gud-energia/"
          >
            <img src={CanalSolarSrcSm} alt="Canal Solar" />
          </S.MediaImg>
          <S.MediaImg
            target="__blank"
            href="https://itforum.com.br/noticias/gud-energia-vivo-auren-joint-venture/"
          >
            <img src={ItForumSrcSm} alt="IT Forum" />
          </S.MediaImg>
        </Slider>
      </S.CarouselWrapper>
      <span className="MediaDesk" />
    </S.Media>
  );
};

export default MediaComponent;
