import { processContent } from "../../../mocks/processContent";
import * as S from "./styles";

const ProcessComponent = () => {
  return (
    <S.Process>
      <S.ProcessRow>
        <h2>Processo</h2>
      </S.ProcessRow>

      <S.ProcessRow>
        <S.ProcessText>Como funciona o processo:</S.ProcessText>
      </S.ProcessRow>

      <S.ProcessList>
        {processContent.map((Process, index) => (
          <S.ProcessItem key={index}>
            <S.ProcessTitle>{`${index + 1}. ${Process}`}</S.ProcessTitle>
          </S.ProcessItem>
        ))}
      </S.ProcessList>

      <span className="ProcessDesk" />
    </S.Process>
  );
};

export default ProcessComponent;
