// Tipos de váriaveis: title, scrollPos, anchorClass, anchor, anchorMob, action, external, pathname
export const headerLinks = [
  {
    title: "Início",
    scrollPos: 5,
    anchorClass: ".start",
    anchor: 0,
    anchorMob: 0,
  },

  {
    title: "Sobre Nós",
    scrollPos: 15,
    anchorClass: ".aboutDesk",
    anchor: -460,
    anchorMob: -440,
  },

  {
    title: "Conheça as vantagens",
    scrollPos: 30,
    anchorClass: ".AdvantageDesk",
    anchor: -300,
    anchorMob: -800,
  },

  {
    title: "Imprensa",
    scrollPos: 56,
    anchorClass: ".MediaDesk",
    anchor: -450,
    anchorMob: -400,
  },

  {
    title: "Perguntas frequentes",
    scrollPos: 60,
    anchorClass: ".faq",
    anchor: -80,
    anchorMob: -80,
  },

  {
    title: "Parceiros",
    pathname: "/parceiros",
  },

  {
    title: "Trabalhe conosco",
    external: "https://gudenergia.inhire.app/vagas",
    action: () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "clickTrabalheConosco",
      });
    },
  },
];
