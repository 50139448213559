import styled from "styled-components"

interface INavHeader {
  $openMobileMenu: boolean
}

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary, #000);
  height: 3.75rem;
  position: sticky;
  top: 0;
  z-index: 100;

  @media (max-width: 1023px) {
    height: 5rem;
  }
`
export const LogoLink = styled.a`
  all: unset;
  cursor: pointer;
  display: flex;
  color: var(--primary,#fff);
  `
  
  export const HamburguerMenuTrigger = styled.button`
  all: unset;
  margin-top: 0.3125rem;
  color: var(--primary,#fff);

  @media (min-width: 1024px) {
    display: none;
  }
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 63.25rem;
  position: relative;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @media (max-width: 767px) {
    padding: 1.5rem;
  }
`

export const NavHeader = styled.nav<INavHeader>`
  ul {
    display: flex;
    align-items: center;
    gap: 0;
    background-color: var(--secondary, #000);

    li {
      color: var(--primary, #fff);
      font-size: 0.875rem;
      line-height: 1.225rem;
      cursor: pointer;
      padding: 0.625rem;
      font-family: "SourceSans3-Light";
      transition: all 0.3s ease-in-out;

      &.active {
        color: var(--primary, #fff);
        border-bottom: 1px solid var(--primary, #fff);
        font-family: "SourceSans3-Bold";
        transition: all 0.3s ease-in-out;
        font-weight: 600;
      }

      &.inactive {
        color: var(--primary, #fff);
        font-weight: 300;
      }

      &:hover {
        color: var(--primary, #fff);
      }
    }
  }

  @media (min-width: 1024px) {
    ul li button {
      display: none;
    }
  }

  @media (max-width: 1023px) {
    transition: transform 0.3s ease-in-out, visibility 0.4s ease-in-out;
    visibility: ${(props) => (props.$openMobileMenu ? "visible" : "hidden")};
    transform: ${(props) =>
      props.$openMobileMenu ? "translateY(0%)" : "translateY(-100%)"};
    position: fixed;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
    top: 80px;
    z-index: 100;

    ul {
      flex-direction: column;
      align-items: flex-end;
      z-index: 1000;
      width: 100%;
      height: 100%;
      padding: 2.5rem;
      padding-top: 1.625rem;
      box-sizing: border-box;
      gap: 0;
      margin: 0;

      li {
        padding: 0.625rem 1rem;
        font-size: 0.875rem;
        line-height: 1.225rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        &.active {
          border-bottom: 0;
          border-right: 1px solid var(--primary, #fff);
        }
      }
    }
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.875rem;
  margin-left: 1rem;
`

export const SocialLink = styled.a`
  text-decoration: none;
  color: var(--primary,#fff);
`
export const WorkWithUslLink = styled.a`
  text-decoration: none;
  color: var(--primary,#fff);
`
