import axios from "axios";
import { FormEvent, useState } from "react";
import InputMask from "react-input-mask";
import ArrowListIcon from "../../../assets/icons/ArrowListIcon";
import SearchIcon from "../../../assets/icons/SearchIcon";
import states from "../../../mocks/states";
import * as S from "./styles";
import { ButtonPrimary } from "@telefonica/mistica";
import { api } from "../../../lib/api";
import MoodSrc from "../../../assets/img/mood.png";

type TCitiesList = Array<{ city: string; id: number }>;
let displayCities = [] as TCitiesList;

const GTM_PARTNER_SUBMIT_FORM = "gtm.partner.submit.form";

const EconomyCalculation = () => {
  const [citiesList, setCitiesList] = useState<TCitiesList>([]);
  const [searchForACity, setSearchForACity] = useState("");
  const [openStatesList, setOpenStatesList] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [openCitiesList, setOpenCitiesList] = useState(false);
  const [openSalesTeamList, setOpenSalesTeamList] = useState(false);
  const [openCnpjList, setOpenCnpjList] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [openSegment, setOpenSegment] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [hasSalesTeam, setHasSalesTeam] = useState("");
  const [cnpjType, setCnpjType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySegment, setCompanySegment] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState<{
    city: string;
    id: number;
  } | null>(null);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [apiError, setApiError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Função handleSendSimulation
  const handleSendSimulation = async (e: FormEvent) => {
    e.preventDefault();

    if (
      !name ||
      !surname ||
      !email ||
      !phone ||
      !hasSalesTeam ||
      !cnpjType ||
      !companyName ||
      !companySegment ||
      !selectedState ||
      !selectedCity
    ) {
      setApiError(
        "Por favor, preencha todos os campos obrigatórios corretamente."
      );
      return;
    }

    setLoadingSimulation(true);
    setApiError("");

    try {
      const formData = {
        nome: name,
        sobrenome: surname,
        email: email,
        telefone: phone,
        possui_time_comercial: hasSalesTeam,
        tipo_cnpj: cnpjType,
        empresa: companyName,
        segmento: companySegment,
        estado: selectedState,
        cidade: selectedCity.city,
        cidade_id: selectedCity.id,
      };

      const response = await api.post(
        import.meta.env.VITE_APP_API_CADASTRO_PARCEIRO,
        formData
      );

      // Verifica se a requisição foi bem-sucedida (status 200)
      if (response.status === 200) {
        setFormSubmitted(true); // Exibe a mensagem de sucesso
        window.scrollTo({ top: 0, behavior: "smooth" }); // Rolagem suave para o topo

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: GTM_PARTNER_SUBMIT_FORM, // Envio do GTM após a submissão do formulário
        });
      } else {
        // Se o status não for 200, lança uma exceção
        throw new Error(
          response.data.mensagem_usuario ||
            "Ocorreu um erro ao enviar o cadastro."
        );
      }
    } catch (e) {
      // Captura e exibe erros
      const errorMessage =
        e instanceof Error
          ? e.message
          : "Ocorreu um erro ao enviar o cadastro.";
      setApiError(errorMessage);
    } finally {
      setLoadingSimulation(false); // Finaliza o estado de carregamento
    }
  };

  const getSegments = () => [
    "Integradora Solar",
    "Usina e empresa de GD",
    "Imobiliário",
    "Centro logístico",
    "Administradora de condomínios",
    "MEI",
    "Outros",
  ];

  const getCNPJTypes = () => ["MEI", "Outros"];

  function removeSpecialCharactersToFilter(value: string) {
    const valueFormatted = value
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .replaceAll(" ", "")
      .toLowerCase();

    return valueFormatted;
  }

  if (searchForACity.length) {
    displayCities = citiesList.filter((cities) => {
      const cityValueWritten = removeSpecialCharactersToFilter(searchForACity);

      const cityToGet = removeSpecialCharactersToFilter(cities.city);

      return cityToGet.includes(cityValueWritten);
    });
  } else {
    displayCities = citiesList.sort((a, b) => a.city.localeCompare(b.city));
  }

  async function handleGetCitiesFromSelectedState(state: string) {
    setSelectedCity(null);
    setOpenCitiesList(false);

    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`;

    try {
      const stateResponse = await axios.get(url);

      const cities = stateResponse.data.map(
        (city: { nome: string; id: number }) => {
          return { city: city.nome, id: city.id };
        }
      );

      setCitiesList(cities);
    } catch (e) {
      console.log("Ocorreu um erro ao buscar pelas cidades...");
    }
  }

  return (
    <S.CalculationRoot>
      <S.CalculationWrapper>
        <S.InfoCol>
          <S.MainHeader>
            Seja um Parceiro GUD Energia* e aumente sua renda com sua carteira
            de clientes!
          </S.MainHeader>

          <S.HeaderText>
            Rentabilize sua carteira de clientes indicando empresas Grupo A para
            o Mercado Livre de Energia. Modelo de comissionamento atrativo,
            suporte completo e zero burocracia
          </S.HeaderText>
          <S.CustomSpan>
            * Nossos parceiros de vendas podem ser parceiros diretos da GUD
            Energia ou através de empresas parceiras GUD Energia
          </S.CustomSpan>
        </S.InfoCol>

        {formSubmitted ? (
          // Mensagem de sucesso após o envio do formulário
          <S.SuccessMessage>
            <h2>Obrigado por enviar suas informações!</h2>
            <p>
              Um representante da GUD irá entrar em <br /> contato com você para
              entender a <br /> situação do seu contrato atual.
            </p>
            <div
              style={{
                alignSelf: "center",
                width: "3.5rem",
                marginTop: "1rem",
              }}
            >
              <img src={MoodSrc} alt="Mood" />
            </div>
          </S.SuccessMessage>
        ) : (
          // Formulário de cadastro
          <S.CalculationCol>
            <S.CalculationForm onSubmit={handleSendSimulation}>
              <h2>Quero aumentar minha renda!</h2>

              {/* Nome e sobrenome */}
              <S.InputsWrapper>
                <S.InputSection>
                  <label id="campoNome">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <S.FloatingText
                      $willFloat={Boolean(name)}
                      className={"name-field"}
                    >
                      Nome
                    </S.FloatingText>
                  </label>
                  <label id="campoSobrenome">
                    <input
                      type="text"
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      required
                    />
                    <S.FloatingText
                      $willFloat={Boolean(surname)}
                      className={"surname-field"}
                    >
                      Sobrenome
                    </S.FloatingText>
                  </label>
                </S.InputSection>
              </S.InputsWrapper>

              {/* Telefone e email */}
              <S.InputsWrapper>
                <S.InputSection>
                  <label id="campoEmail">
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <S.FloatingText
                      $willFloat={Boolean(email)}
                      className={"email-field"}
                    >
                      Email
                    </S.FloatingText>
                  </label>

                  <label id="campoTelefoneContato">
                    <InputMask
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                      mask="(99) 9 9999 9999"
                    />
                    <S.FloatingText
                      $willFloat={Boolean(phone)}
                      className={"phone-field"}
                    >
                      Telefone de contato
                    </S.FloatingText>
                  </label>
                </S.InputSection>
              </S.InputsWrapper>

              {/* Time comercial e tipo de CNPJ */}
              <S.InputsWrapper>
                <S.TriggerWrapper
                  className="sales-team-field"
                  $floatText={Boolean(hasSalesTeam)}
                  $changeColor={Boolean(hasSalesTeam)}
                >
                  <button
                    className={openSalesTeamList ? "openedList" : ""}
                    type="button"
                    onClick={() => {
                      setOpenSalesTeamList(!openSalesTeamList);
                    }}
                  >
                    <p className="miniText">Possui time comercial?</p>
                    {hasSalesTeam} <ArrowListIcon />
                  </button>
                  <S.List $openList={openSalesTeamList}>
                    {["Sim", "Não"].map((option) => (
                      <li
                        key={option}
                        onClick={() => {
                          setHasSalesTeam(option);
                          setOpenSalesTeamList(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </S.List>
                </S.TriggerWrapper>

                <S.TriggerWrapper
                  className="cnpj-type-field"
                  $floatText={Boolean(cnpjType)}
                  $changeColor={Boolean(cnpjType)}
                >
                  <button
                    className={openCnpjList ? "openedList" : ""}
                    type="button"
                    onClick={() => {
                      setOpenCnpjList(!openCnpjList);
                    }}
                  >
                    <p className="miniText">Tipo de CNPJ</p>
                    {cnpjType} <ArrowListIcon />
                  </button>
                  <S.List $openList={openCnpjList}>
                    {getCNPJTypes().map((type) => (
                      <li
                        key={type}
                        onClick={() => {
                          setCnpjType(type);
                          setOpenCnpjList(false);
                        }}
                      >
                        {type}
                      </li>
                    ))}
                  </S.List>
                </S.TriggerWrapper>
              </S.InputsWrapper>

              {/* Segmento e empresa */}
              <S.InputsWrapper>
                <label id="campoEmpresaNome">
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                  <S.FloatingText
                    $willFloat={Boolean(companyName)}
                    className={"company-field"}
                  >
                    Empresa
                  </S.FloatingText>
                </label>
                <S.TriggerWrapper
                  className="segment-type-field"
                  $floatText={Boolean(companySegment)}
                  $changeColor={Boolean(companySegment)}
                >
                  <button
                    className={openSegment ? "openedList" : ""}
                    type="button"
                    onClick={() => {
                      setOpenSegment(!openSegment);
                    }}
                  >
                    <p className="miniText">Segmento</p>
                    {companySegment} <ArrowListIcon />
                  </button>
                  <S.List $openList={openSegment}>
                    {getSegments().map((type) => (
                      <li
                        key={type}
                        onClick={() => {
                          setCompanySegment(type);
                          setOpenSegment(false);
                        }}
                      >
                        {type}
                      </li>
                    ))}
                  </S.List>
                </S.TriggerWrapper>
              </S.InputsWrapper>

              {/* Estado e cidade */}
              <S.InputsWrapper>
                <div className="labelDiv">
                  Minha empresa está localizada em:
                  <S.InputSection onClick={(e) => e.stopPropagation()}>
                    <S.TriggerWrapper
                      id="campoEstado"
                      className="state-field"
                      $floatText={Boolean(selectedState)}
                      $changeColor={Boolean(selectedState)}
                    >
                      <button
                        className={openStatesList ? "openedList" : ""}
                        type="button"
                        onClick={() => {
                          setOpenStatesList(!openStatesList);
                        }}
                      >
                        <p className="miniText">Estado (UF)</p>
                        {selectedState} <ArrowListIcon />
                      </button>
                      <S.List $openList={openStatesList}>
                        {states.map((state) => {
                          return (
                            <li
                              onClick={() => {
                                setStateError(false);
                                setSelectedState(state);
                                setOpenStatesList(false);
                                handleGetCitiesFromSelectedState(state);
                              }}
                              key={state}
                            >
                              {state}
                            </li>
                          );
                        })}
                      </S.List>
                      <S.InputError>{stateError && "Obrigatório"}</S.InputError>
                    </S.TriggerWrapper>

                    <S.TriggerWrapper
                      id="campoMunicipio"
                      className="city-field"
                      $floatText={Boolean(selectedCity)}
                      $changeColor={Boolean(selectedCity)}
                    >
                      <button
                        disabled={!citiesList.length}
                        className={openCitiesList ? "openedList" : ""}
                        type="button"
                        onClick={() => {
                          setOpenCitiesList(!openCitiesList);
                        }}
                      >
                        <p className="miniText">Município</p>
                        {selectedCity?.city} <ArrowListIcon />
                      </button>
                      <S.List $openList={openCitiesList}>
                        <S.SearchCityInput>
                          <input
                            type="text"
                            onChange={({ target }) =>
                              setSearchForACity(target.value)
                            }
                          />
                          <SearchIcon />
                        </S.SearchCityInput>
                        {displayCities.map(({ city, id }) => {
                          return (
                            <li
                              key={id}
                              onClick={() => {
                                setCityError(false);
                                setOpenCitiesList(false);
                                setSelectedCity({ city, id });
                              }}
                            >
                              {city}
                            </li>
                          );
                        })}
                      </S.List>
                      <S.InputError>{cityError && "Obrigatório"}</S.InputError>
                    </S.TriggerWrapper>
                  </S.InputSection>
                </div>
              </S.InputsWrapper>

              {/* Mensagem de erro da API */}
              {apiError && (
                <S.ApiError>
                  <p>{apiError}</p>
                </S.ApiError>
              )}

              {/* Botão de envio */}
              <S.SimulateButton $formLoading={loadingSimulation}>
                <ButtonPrimary
                  loadingText="Enviando..."
                  showSpinner={loadingSimulation}
                  submit
                >
                  Enviar
                </ButtonPrimary>
              </S.SimulateButton>
            </S.CalculationForm>
          </S.CalculationCol>
        )}
      </S.CalculationWrapper>
    </S.CalculationRoot>
  );
};

export default EconomyCalculation;
