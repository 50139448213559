export const benefitsContent = [
  {
    topic: "💰 Ganhe mais dinheiro com sua carteira de clientes",
    text: "🔹 Comissionamento atrativo ao migrar clientes para o Mercado Livre de Energia.",
  },
  {
    topic: "📈 Venda mais sem esforço",
    text: "🔹 Qualifique seu portfólio e aumente suas oportunidades de negócios.",
  },
  {
    topic: "🤝 Suporte total e treinamento especializado",
    text: "🔹 Nosso time te capacita e te acompanha para garantir seu sucesso.",
  },
  {
    topic: "⚡ Modelo simples, sem burocracia",
    text: "🔹 Você indica, nós cuidamos do processo e você recebe sua comissão.",
  },
];
