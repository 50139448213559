import FooterComponent from "./components/FooterComponent";
import HeaderComponent from "./components/HeaderComponent";
import GlobalStyle from "./globalStyles/globalStyles";
import Home from "./components/Home";
import TermsOfService from "./components/TermsOfService";
import Partner from "./components/Partner";
import ContractComponent from "./components/ContractComponent";
import GlobalStorage from "./context/globalContext";
import ExitPopup from "./components/PopUpLeaveComponent"; // Importando o ExitPopup
import Cookie from "js-cookie";
import "@telefonica/mistica/css/mistica.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LOGIN_TIMESTAMP_KEY } from "./utils/constants";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { ThemeContextProvider, getMovistarSkin } from "@telefonica/mistica";
import CookieModal from "./components/CookieModal";
import { useEffect } from "react";

const misticaTheme = {
  skin: getMovistarSkin(),
  i18n: { locale: "pt-BR", phoneNumberFormattingRegionCode: "BR" },
};

function App() {
  const acceptCookies = Cookie.get("vivo-cookie-auth");

  const getBody = document.querySelector("body") as HTMLElement;

  if (!acceptCookies) {
    getBody.style.overflow = "hidden";
  } else {
    getBody.style.overflow = "unset";
  }

  useEffect(() => {
    const loginTimeStamp = sessionStorage.getItem(LOGIN_TIMESTAMP_KEY);

    if (!loginTimeStamp) {
      sessionStorage.setItem(LOGIN_TIMESTAMP_KEY, Date.now().toString());
    }
  }, []);

  function AppContent() {
    const location = useLocation(); // Agora dentro do Router!

    return (
      <>
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/normas-de-privacidade" element={<TermsOfService />} />
          <Route path="/contratos" element={<ContractComponent />} />
          <Route path="/parceiros" element={<Partner />} />
        </Routes>
        {/* Exibe o popup apenas se NÃO estiver em "/parceiros" */}
        {location.pathname !== "/parceiros" && <ExitPopup />}
        <FooterComponent />
        {!acceptCookies && <CookieModal />}
      </>
    );
  }

  return (
    //@ts-ignore
    <ThemeContextProvider theme={misticaTheme}>
      <GlobalStorage>
        <GlobalStyle />
        <Router>
          <AppContent />
        </Router>
      </GlobalStorage>
    </ThemeContextProvider>
  );
}

export default App;
