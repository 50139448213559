import React, { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/globalContext";
import {
  invisibleButtonStyle,
  overlayStyle,
  popupStyle,
  closeButtonStyle,
} from "./styles";
import { LOGIN_TIMESTAMP_KEY } from "../../utils/constants";

const POP_UP_DELAY = 15000;
const MIN_LOGIN_TIME = 30000;
const eventListener = "mouseleave";
const GTM_POPUP_SHOW = "gtm.popup.show";
const GTM_POPUP_CLICK = "gtm.popup.click";

const ExitPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [_, setLastPopupSeen] = useState<number>(0);

  const { showSimulationResult } = useContext(GlobalContext);

  const checkIfShouldShow = window.location.pathname === "/" && !showSimulationResult;



  const isValidLogging = useCallback((): boolean => {
    const loginTimestamp = sessionStorage.getItem(LOGIN_TIMESTAMP_KEY);
    if (!loginTimestamp) return false;
    const elapsedTime = Date.now() - parseInt(loginTimestamp, 10);
    return elapsedTime >= MIN_LOGIN_TIME;
  }, []);

  const handleMouseLeave = useCallback((event: MouseEvent): void => {
    const mouseOut = event.clientY < 0 || event.clientX < 0;
    if (mouseOut && isValidLogging()) {
      setLastPopupSeen((prevLastPopupSeen) => {
        const dateNow = Date.now();
        if (dateNow - prevLastPopupSeen > POP_UP_DELAY) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: GTM_POPUP_SHOW,
          });
          setShowPopup(true);
          return dateNow;
        }
        return prevLastPopupSeen;
      });
    }
  }, [isValidLogging]);

  useEffect(() => {
    if (!checkIfShouldShow) return;

    document.addEventListener(eventListener, handleMouseLeave);
    return () => {
      document.removeEventListener(eventListener, handleMouseLeave);
    };
  }, [checkIfShouldShow, handleMouseLeave]);

  const followLinkClick = (): void => {
    setShowPopup(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: GTM_POPUP_CLICK,
    });
  };

  return (
    <>
      {showPopup && (
        <div className="popup_exit" style={overlayStyle}>
          <div style={popupStyle}>
            <button
              onClick={followLinkClick}
              style={invisibleButtonStyle}
              aria-label="Clique aqui"
            ></button>

            {/* Botão de fechar */}
            <button
              onClick={() => setShowPopup(false)} // Fecha o popup
              style={closeButtonStyle}
              aria-label="Fechar"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ExitPopup;
