import { useContext, useEffect, useState, Fragment } from "react";
import { GlobalContext } from "../../context/globalContext";
import * as S from "./styles";
import handleResponsiveScroll from "../../mocks/handleResponsiveScroll";
import InfoSentIcon from "../../assets/icons/InfoSentIcon";
import AdvantageIcon from "../../assets/icons/AdvantageIcon";
import QuestionsModal from "../QuestionsModal";

interface ISucessMessage {
  ineligible: boolean;
}

const SuccessMessage = ({ ineligible }: ISucessMessage) => {
  const {
    resultInformations,
    setResultInformations,
    setShowIneligibleScreen,
    setMonthlyValue,
    setSelectedCity,
    setSelectedState,
    setShowSimulationResult,
  } = useContext(GlobalContext);

  const [showQuestionsModal, setShowQuestionsModal] = useState(false);

  function handleRedoSimulation() {
    setShowIneligibleScreen(false);
    setShowSimulationResult(false);
    setShowQuestionsModal(false);
    setResultInformations(null);
    setSelectedCity(null);
    setMonthlyValue("");
    setSelectedState("");

    setTimeout(() => {
      handleResponsiveScroll(".economySimulation", { desk: -590, mob: -340 });
    }, 300);
  }

  useEffect(() => {
    handleResponsiveScroll(".successMessage", { desk: -500, mob: -150 });
  }, []);

  return (
    <Fragment>
      {!showQuestionsModal ? (
        <S.SuccessMessageRoot>
          <S.SuccessMessageWrapper className="inelegibleSimulation">
            <S.InfoCol>
              {ineligible ? (
                <>
                  <S.MainHeader>
                    Economize até 30% na conta de luz escolhendo a GUD Energia.
                  </S.MainHeader>

                  <S.HeaderText>
                    Venha para o Mercado Livre de Energia com a GUD!
                    Cuidamos de todo o processo de migração sem nenhum custo e sem burocracia para sua empresa.
                  </S.HeaderText>
                </>
              ) : (
                <>
                  <S.DiscountHeader>
                    Pronto para economizar com a GUD Energia?
                  </S.DiscountHeader>

                  <S.DiscountValue>
                    {resultInformations?.percentage ?? "0"}% ao mês!
                  </S.DiscountValue>

                  <p>Em 5 anos, isso pode representar uma economia de:</p>

                  <S.DiscountValueIn3Years>
                    {resultInformations?.economy ?? "R$ 00,00"}
                  </S.DiscountValueIn3Years>

                  <S.DiscountAdvantageTextRow>
                    <AdvantageIcon />
                    <p>
                      Sem mudar seu padrão de consumo e sem necessidade de
                      investimento!
                    </p>
                  </S.DiscountAdvantageTextRow>

                  <S.SmallText>
                    Essa simulação de desconto possui efeitos indicativos e não
                    deve ser considerada como uma proposta comercial.{" "}
                    <strong>
                      Para saber o desconto exato, envie uma fatura recente de
                      energia elétrica e aguarde o contato de um consultor.
                    </strong>
                  </S.SmallText>
                </>
              )}
            </S.InfoCol>
            <S.SuccessMessageFormCol>
              <S.SuccessMessageFormWrapper>
                {ineligible ? (
                  <>
                    <h2>
                      Obrigado por enviar <br /> suas informações
                    </h2>
                    <p>
                      Caso você se torne elegível, um representante GUD entrará
                      em contato com uma proposta para você!
                    </p>

                    <div
                      style={{
                        alignSelf: "center",
                        width: "8rem",
                        marginTop: "1rem",
                      }}
                    >
                      <InfoSentIcon />
                    </div>

                    <S.RedoSimulationAlt id="btnSimular">
                      <button
                        onClick={() => handleRedoSimulation()}
                        type="button"
                      >
                        Simular novamente
                      </button>
                    </S.RedoSimulationAlt>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        alignSelf: "flex-start",
                        width: "3.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      <InfoSentIcon />
                    </div>
                    <h2>
                      Recebemos suas <br></br> informações de contato
                    </h2>
                    <p>
                      Caso seja elegível, em até 72 horas um representante GUD
                      entrará em contato com uma proposta para você!
                    </p>
                    <span>
                      Responda 3 perguntas para ser atendido por um
                      representante GUD em até 24 horas!
                    </span>
                    <S.QuestionsButton>
                      <button
                        onClick={() => setShowQuestionsModal(true)}
                        type="button"
                      >
                        Responder perguntas
                      </button>
                    </S.QuestionsButton>
                  </>
                )}
              </S.SuccessMessageFormWrapper>
            </S.SuccessMessageFormCol>
          </S.SuccessMessageWrapper>
        </S.SuccessMessageRoot>
      ) : (
        <QuestionsModal />
      )}
    </Fragment>
  );
};

export default SuccessMessage;
