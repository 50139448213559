import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseMenuIcon from "../../assets/icons/CloseMenuIcon";
import HamburguerMenu from "../../assets/icons/HamburguerMenu";
import Logo from "../../assets/icons/Logo";
import InstagramIcon from "../../assets/icons/social/InstagramIcon";
import LinkedinIcon from "../../assets/icons/social/LinkedinIcon";
import { useIsMobile } from "../../hooks/useIsMobile";
import { INSTAGRAM_URL, LINKEDIN_URL } from "../../mocks/externalUrls";
import { headerLinks } from "../../mocks/headerLinks";
import * as S from "./styles";

const HeaderComponent = () => {
  const [activeMenu, setActiveMenu] = useState("Início");
  const [openMenuMobile, setOpenMenuMobile] = useState(false);

  const isMobile = useIsMobile(768);

  const navigate = useNavigate();
  const location = useLocation();

  function goToDesiredComponent(anchorElement: string, scrollValue: number) {
    const isHomePage = location.pathname === "/";

    // Se não estiver na página inicial, move para lá primeiro
    if (!isHomePage) {
      handleScroll();
      navigate("/", { state: { anchorElement, scrollValue } });
    }

    if (anchorElement === ".start") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return;
    }

    const element = document.querySelector(anchorElement) as HTMLElement | null;

    if (!element) {
      console.warn(`Elemento ${anchorElement} não encontrado.`);
      return;
    }

    const scrollVal =
      element.getBoundingClientRect().top + window.scrollY + scrollValue;

    window.scrollTo({ top: scrollVal, behavior: "smooth" });
  }
  useEffect(() => {
    // Atualiza o estado com base na rota
    if (location.pathname === "/parceiros") {
      setActiveMenu("Parceiros");
    } else {
      setActiveMenu("Início"); // Volta para "Início" se a rota não for "/parceiros"
    }
  }, [location.pathname]);
  const handleScroll = () => {
    const maxHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const offSet = (window.scrollY * 100) / maxHeight;

    let closestSection = null;
    let smallestDiff = Infinity;

    for (const link of headerLinks) {
      if (!link.scrollPos) continue;

      const determineScrollPos =
        window.innerHeight <= 600 ? link.scrollPos - 2 : link.scrollPos;

      const diff = Math.abs(offSet - determineScrollPos);

      if (diff < smallestDiff) {
        smallestDiff = diff;
        closestSection = link.title;
      }
    }
    if (closestSection) {
      setActiveMenu(closestSection);
    }
  };

  useEffect(() => {
    if (location.pathname === "/")
      window.addEventListener("scroll", handleScroll);

    if (!isMobile) setOpenMenuMobile(false);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile, location.pathname]);

  const acceptCookies = Cookie.get("vivo-cookie-auth");

  if (acceptCookies) {
    const getBody = document.querySelector("body") as HTMLElement;

    if (openMenuMobile) {
      getBody.style.overflow = "hidden";
    } else {
      getBody.style.overflow = "unset";
    }
  }

  function handleNavigation(link: any) {
    setOpenMenuMobile(false);

    if (link.external) {
      window.open(link.external, "_blank", "noopener,noreferrer");
    } else if (link.pathname) {
      setActiveMenu(link.title);
      navigate(link.pathname);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      goToDesiredComponent(
        link.anchorClass,
        window.innerWidth <= 1024 ? link.anchorMob : link.anchor
      );
    }

    if (link.action) link.action.call();
  }

  return (
    <S.Header>
      <S.HeaderWrapper>
        {/* Logo */}
        <S.LogoLink href="/">
          <Logo />
        </S.LogoLink>
        {/* Logo */}

        {/* Menu Hamburgues */}
        <S.HamburguerMenuTrigger
          onClick={() => setOpenMenuMobile(!openMenuMobile)}
          type="button"
        >
          {openMenuMobile ? <CloseMenuIcon /> : <HamburguerMenu />}
        </S.HamburguerMenuTrigger>
        {/* Menu Hamburguer */}

        {/* Menu de Navegação */}
        <S.NavHeader $openMobileMenu={openMenuMobile}>
          <ul>
            {/* Links do Menu */}
            {headerLinks.map((link) => (
              <li
                key={link.title}
                className={activeMenu === link.title ? "active" : ""}
                onClick={() => {
                  handleNavigation(link);
                }}
              >
                {link.title}
              </li>
            ))}

            {/* Redes Sociais */}
            <li>
              <S.SocialWrapper>
                <S.SocialLink target="_blank" href={INSTAGRAM_URL}>
                  <InstagramIcon />
                </S.SocialLink>
                <S.SocialLink target="_blank" href={LINKEDIN_URL}>
                  <LinkedinIcon />
                </S.SocialLink>
              </S.SocialWrapper>
            </li>
          </ul>
        </S.NavHeader>
      </S.HeaderWrapper>
    </S.Header>
  );
};

export default HeaderComponent;
