import styled from "styled-components";

export const Process = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }

  h2 {
    font-family: "SourceSans3-Bold";
    color: var(--black2, #1d1d1d);
    font-size: 2rem;
    line-height: 2.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  background-color: #fff;
`;

export const ProcessRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
`;

export const ProcessText = styled.div`
  font-family: "SourceSans3-Regular";
  max-width: 39rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-wrap: balance;

  strong {
    font-family: "SourceSans3-Bold";
    color: var(--primary, #000);
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
  align-items: center;
`;

export const ProcessList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProcessItem = styled.li`
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  border-left: 4px solid var(--primary, #fff);
  border-radius: 10px;
  width: 60%;
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 10px;
  box-shadow: 1px 4px 16px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: 767px) {
    width: 80%;
    margin: 0;
  }
`;

export const ProcessTitle = styled.span`
  color: #333;
  font-family: "SourceSans3-Regular";
  align-items: center;
`;
