import styled from "styled-components";

export const Benefits = styled.div`
  padding: 20px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2.5rem 6rem 2.5rem;
  gap: 1rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  h2 {
    font-family: "SourceSans3-Bold";
    color: var(--black2, #1d1d1d);
    font-size: 2rem;
    line-height: 2.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  background-color: #f9f9f9;
`;

export const BenefitsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 59.25rem;
  gap: 2rem;

  .Benefits {
    height: 0;
    width: 0;
  }
`;

export const BenefitsContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
`;

export const BenefitsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

export const BenefitsHeader = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
`;

export const BenefitsDescription = styled.p`
  font-family: "SourceSans3-Regular";
  max-width: 39rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-wrap: balance;

  strong {
    font-family: "SourceSans3-Bold";
    color: var(--primary, #000);
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
  align-items: center;
`;
