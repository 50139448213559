import FaqComponent from "../FaqComponent";
import BenefitsComponent from "./BenefitsComponent";
import ParticipateComponent from "./ParticipateComponent";
import PartnerQuestions from "./PartnerQuestions";
import ProcessComponent from "./ProcessComponent";
import { PartnerContainer } from "./styles";

const Partner = () => {
  return (
    <PartnerContainer>
      <PartnerQuestions />
      <BenefitsComponent />
      <ProcessComponent />
      <ParticipateComponent />
      <FaqComponent filterType="partner" />
    </PartnerContainer>
  );
};

export default Partner;
