import * as S from "./styles";

const ParticipateComponent = () => {
  return (
    <S.Participate>
      <S.ParticipateRow>
        <h2>Quem pode participar?</h2>
      </S.ParticipateRow>

      <S.ParticipateRow>
        <S.ParticipateText>
          Empresas que possuam um CNPJ ativo; e tenham carteira de clientes, ou
          acesso a empresas conectadas à rede de média ou alta tensão (Grupo A)
        </S.ParticipateText>
      </S.ParticipateRow>

      <span className="aboutDesk" />
    </S.Participate>
  );
};

export default ParticipateComponent;
