import React from "react";
import popUpExitImage from "../../assets/img/pop_up_exit.png";

export const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

export const popupStyle: React.CSSProperties = {
  position: "relative",
  width: "356px",
  height: "450px",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  backgroundImage: `url(${popUpExitImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

export const invisibleButtonStyle: React.CSSProperties = {
  position: "absolute",
  top: "70%",
  transform: "translateX(20%)",
  width: "246px",
  height: "46px",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  color: "transparent",
};

export const closeButtonStyle: React.CSSProperties = {
  position: 'absolute',
  top: '25px',
  right: '25px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#000',
};