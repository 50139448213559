import * as S from "./styles"; // Certifique-se de que seus estilos estão configurados corretamente
import { contractContent } from "../../mocks/contractsContent"; // Importando o conteúdo dos contratos
import { documentsContent } from "../../mocks/documentsContent"; // Importando o conteúdo dos contratos

const ContractsContent = () => {
  const activeContracts = contractContent.filter(contract => contract.active);
  const inactiveContracts = contractContent.filter(contract => !contract.active);
  const activeDocuments = documentsContent.filter(document => document.active);

  return (
    <S.ContractsContainer>
      <S.ContractsWrapper>
        <S.TitleText>Contratos</S.TitleText>

        <div>
          {activeContracts.map((contract, index) => (
            <S.ContractLink href={contract.link} target="_blank" rel="noopener noreferrer" key={index}>
              <S.ContractsItem>
                <S.BulletPoint />
                <p>{contract.text}</p>
              </S.ContractsItem>
            </S.ContractLink>
          ))}
        </div>

        {inactiveContracts.length > 0 && <S.TitleText>Contratos antigos</S.TitleText>}

        <div>
          {inactiveContracts.map((contract, index) => (
            <S.ContractLink href={contract.link} target="_blank" rel="noopener noreferrer" key={index}>
              <S.ContractsItem>
                <S.BulletPoint />
                <p>{contract.text}</p>
              </S.ContractsItem>
            </S.ContractLink>
          ))}
        </div>

        <S.TitleText>Documentos</S.TitleText>

        <div>
          {activeDocuments.map((document, index) => (
            <S.ContractLink href={document.link} target="_blank" rel="noopener noreferrer" key={index}>
              <S.ContractsItem>
                <S.BulletPoint />
                <p>{document.text}</p>
              </S.ContractsItem>
            </S.ContractLink>
          ))}
        </div>
      </S.ContractsWrapper>
    </S.ContractsContainer>
  );
};

export default ContractsContent;
