import React, { useMemo } from "react";
import { questionsContent } from "../../mocks/questionsContent";
import FaqContent from "../FaqContent";
import * as S from "./styles";

interface FaqComponentProps {
  filterType: string | null; // Define o tipo de filtro (ex: "Partner" ou null)
}

// Função para embaralhar (Algoritmo de Fisher-Yates)
function shuffleArray<T>(array: T[]): T[] {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

const FaqComponent: React.FC<FaqComponentProps> = ({ filterType }) => {
  const filteredQuestions = useMemo(() => {
    return questionsContent.filter((question) =>
      filterType ? question.type === filterType : !question.type
    );
  }, [filterType]);

  const mixedQuestions = useMemo(
    () => shuffleArray(filteredQuestions),
    [filteredQuestions]
  );

  return (
    <S.FaqContainer>
      <S.FaqWrapper>
        <span className="faq" />

        <S.TitleText>Perguntas Frequentes da FAQ</S.TitleText>

        <S.Faqs>
          {mixedQuestions.map(({ question, answer, apiInfo }) => {
            return (
              <FaqContent
                key={question}
                question={question}
                answer={answer}
                apiInfo={apiInfo}
              />
            );
          })}
        </S.Faqs>
      </S.FaqWrapper>
    </S.FaqContainer>
  );
};

export default FaqComponent;
