import { benefitsContent } from "../../../mocks/benefitsContent";
import * as S from "./styles";

const BenefitsComponent = () => {
  return (
    <S.Benefits>
      <S.BenefitsRow>
        <h2>Benefícios</h2>
      </S.BenefitsRow>

      <S.BenefitsRow>
        <S.BenefitsDescription>
          Porque ser um parceiro GUD Energia?
        </S.BenefitsDescription>
      </S.BenefitsRow>

      <S.BenefitsContainer>
        {benefitsContent.map((benefit, index) => (
          <S.BenefitsContent key={index}>
            <S.BenefitsHeader>{benefit.topic}</S.BenefitsHeader>
            <S.BenefitsDescription>{benefit.text}</S.BenefitsDescription>
          </S.BenefitsContent>
        ))}
      </S.BenefitsContainer>
    </S.Benefits>
  );
};

export default BenefitsComponent;
